import {
  routeToListing,
  routeToListings,
  routeToPublicListing,
  routeToPublicListings
} from '../../store/selectors/routes'
import { useEffect } from 'react'
import history from '../../history'
import { setFilter } from '../../actions/filters'
import { LISTING } from '../../constants/listings'
import { useDispatch, useSelector } from 'react-redux'
import { constructFiltersAndOmitEmpty } from 'src/helpers/listings/filters'

export default (filter, customerId) => {
  const dispatch = useDispatch()
  const isPublic = useSelector(state => state.auth.isPublic)
  const isLead = useSelector(state => state.auth.isLead)
  if (isLead) {
    return
  }

  useEffect(() => {
    if (filter && filter.location && (customerId || isPublic)) {
      const { location } = filter

      if (location.level === LISTING) {
        history.push(
          isPublic ? routeToPublicListing(location.payload.id) : routeToListing(customerId, location.payload.id)
        )
      } else {
        dispatch(setFilter(filter))
        history.push(
          isPublic
            ? routeToPublicListings(location.level, location.name, constructFiltersAndOmitEmpty(filter))
            : routeToListings(customerId, location.level, location.name, constructFiltersAndOmitEmpty(filter))
        )
      }
    }
  }, [dispatch, filter, filter?.location, filter?.order, filter?.additionalFilters, customerId, isPublic])
}
