import { APPLE, GOOGLE, APPLE_URL, GOOGLE_URL } from '../../../constants/native_app'
import { AppleEnglish } from './badges/AppleEnglish'
import { AppleSpanish } from './badges/AppleSpanish'
import { useIntl, defineMessages } from 'react-intl'
import { Link, ComponentTrackingType } from '@homebotapp/hb-react-component-catalog'
import { GoogleSpanish } from './badges/GoogleSpanish'
import { GoogleEnglish } from './badges/GoogleEnglish'
import { useAppSelector } from '../../../store/hooks'
import { selectNativeShareUrl } from '../../../store/selectors/customerProfile'

const MSG = defineMessages({
  google: {
    id: 'NativeAppStoreBadge.google',
    defaultMessage: 'Get it on Google Play'
  },
  apple: {
    id: 'NativeAppStoreBadge.apple',
    defaultMessage: 'Download on the Apple App Store'
  }
})

interface AppStoreBadgeProps {
  locale?: string
  tracking: ComponentTrackingType
  platform: typeof APPLE | typeof GOOGLE
}

export const AppStoreBadge = ({ platform, tracking, locale = 'en' }: AppStoreBadgeProps) => {
  const intl = useIntl()

  const isLead = useAppSelector(state => state.auth.isLead)
  const nativeShareUrl = useAppSelector(selectNativeShareUrl)

  const getSVGLink = () => {
    if (platform === GOOGLE) {
      return locale === 'es' ? (
        <GoogleSpanish a11yTitle={intl.formatMessage(MSG.google)} />
      ) : (
        <GoogleEnglish a11yTitle={intl.formatMessage(MSG.google)} />
      )
    }

    return locale === 'es' ? (
      <AppleSpanish a11yTitle={intl.formatMessage(MSG.apple)} />
    ) : (
      <AppleEnglish a11yTitle={intl.formatMessage(MSG.apple)} />
    )
  }

  return (
    <Link
      target='_blank'
      rel='noreferrer'
      href={isLead && nativeShareUrl ? nativeShareUrl : platform === APPLE ? APPLE_URL : GOOGLE_URL}
      tracking={{
        ...tracking,
        descriptor: platform === APPLE ? MSG.apple.defaultMessage : MSG.google.defaultMessage
      }}
    >
      {getSVGLink()}
    </Link>
  )
}
