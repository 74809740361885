import { useEffect, useRef } from 'react'
import classnames from 'classnames'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Text,
  useTheme,
  Heading,
  Logo,
  Card,
  Avatar,
  Button,
  Icon,
  Flex
} from '@homebotapp/hb-react-component-catalog'
import { LandingPageIconCard } from '../../../Components/homeSearch/LandingPageIconCard/LandingPageIconCard'
import searchPurlBackgroundLight from './assets/search-purl-bg-light.png'
import searchPurlBackgroundDark from './assets/search-purl-bg-dark.png'
import topographMapLight from '../../../assets/homes/img/topo-bg-wide-light.svg'
import topographMapDark from '../../../assets/homes/img/topo-bg-wide.svg'
import { NativeCTALinks } from '../../../Components/shared/NativeCTALinks/NativeCTALinks'
import { HomeSearchTrackingEventLocation } from '../../../constants/tracking'
import { HomeSearchFooter } from '../../../Components/homeSearch/HomeSearchFooter/HomeSearchFooter'
import { toggleListingSearchModal } from '../../../actions/listings'
import Modal from '../../../Components/shared/Modal/'
import { SearchModal } from '../../../Components/homeSearch/Search/SearchModal'
import { selectShowListingSearchModal } from '../../../store/selectors/listings'
import { setIsLead } from '../../../store/slices/auth'

import modalStyles from '../../../Components/shared/Modal/Modal.module.scss'
import { HOME_SEARCH_LANDING_PAGE } from '../../../constants/tracking/trackingEventLocations/homeSearchTrackingEventLocation'
import { fetchProfile } from '../../../actions/customerProfile'
import { useAppSelector } from '../../../store/hooks'
import { setAgentToken } from '../../../auth/widget'
import { SET_CLIENT_IS_GUEST } from '../../../actions/actionTypes'

export interface HomeSearchLandingPageProps {
  match: any
}

export const MSG = defineMessages({
  heading: {
    id: 'HomeSearch.landing.page.heading',
    defaultMessage: 'Find your next home'
  },
  today: {
    id: 'HomeSearch.landing.page.today',
    defaultMessage: 'today'
  },
  intro: {
    id: 'HomeSearch.landing.page.intro',
    defaultMessage:
      "👋 Hi, I'm {customerFullName} with {companyName}, and I'm here to help you through every step of your homeownership journey."
  },
  introDefault: {
    id: 'HomeSearch.landing.page.intro.default',
    defaultMessage: "👋 Hi, I'm here to help you through every step of your homeownership journey."
  },
  message: {
    id: 'HomeSearch.landing.page.handShake',
    defaultMessage: 'Your trusted professionals at your fingertips to offer expert guidance'
  },
  search: {
    id: 'HomeSearch.landing.page.search',
    defaultMessage: 'Explore nationwide to find affordable options perfect for you'
  },
  wallet: {
    id: 'HomeSearch.landing.page.wallet',
    defaultMessage: 'Personalized financial insights help you make informed decisions'
  },
  mobileCTA: {
    id: 'HomeSearch.landing.page.mobileCTA',
    defaultMessage: 'Search on the go with the Homebot Mobile App'
  },
  enterLocation: {
    id: 'HomeSearch.landing.page.enterLocation',
    defaultMessage: 'Enter a location'
  }
})

export const HomeSearchLandingPage = ({ match }: HomeSearchLandingPageProps) => {
  const intl = useIntl()

  const { theme } = useTheme()

  const dispatch = useDispatch()

  const customerProfilePublicKey = useAppSelector(state => state.customerProfile?.data?.publicKey)
  const { customerProfileId: customerProfileIdFromUrl } = match.params

  const showSearchModal = useSelector(selectShowListingSearchModal)

  const locationSearchInputRef = useRef<HTMLInputElement>(null)

  const firstName = useAppSelector(state => state.customerProfile?.data?.firstName)
  const LastName = useAppSelector(state => state.customerProfile?.data?.lastName)
  const customerFullName = `${firstName} ${LastName}`
  const photoUri = useAppSelector(state => state.customerProfile?.data?.photoUri)

  const corporateName = useAppSelector(state => state.customerProfile?.data?.corporationName)

  useEffect(() => {
    dispatch(setIsLead(true))
    dispatch({
      type: SET_CLIENT_IS_GUEST,
      data: true
    })
  }, [dispatch])

  useEffect(() => {
    if (customerProfileIdFromUrl) {
      dispatch(fetchProfile(customerProfileIdFromUrl))
    }
  }, [dispatch, customerProfileIdFromUrl])

  useEffect(() => {
    if (customerProfilePublicKey) {
      setAgentToken(customerProfilePublicKey)
    }
  }, [customerProfilePublicKey])

  return (
    <>
      <Modal
        className={classnames(modalStyles.modal, modalStyles.searchModal)}
        open={showSearchModal}
        toggle={() => dispatch(toggleListingSearchModal(false))}
        overrideContent
      >
        <SearchModal locationSearchInputRef={locationSearchInputRef} />
      </Modal>
      <Box display='flex' alignItems='center' flexDirection='column'>
        <Box width={'100%'}>
          <Box
            display='flex'
            justifyContent='center'
            height={['240px', '300px', '440px', '460px', '600px', '662px']}
            backgroundSize={['cover', '155%', '145%', '140%', '135%', '100%']}
            backgroundRepeat={'no-repeat'}
            backgroundPosition={['center', '100% 75%', '100% 0%', '98% 75%', '98% 52%', '100% 0%']}
            backgroundImage={`linear-gradient(74.97deg, rgba(30, 28, 35, 0.2) 36.57%, rgba(30, 28, 35, 0.75) 91.29%), url(${
              theme.isLightThemeType ? searchPurlBackgroundLight : searchPurlBackgroundDark
            })`}
          >
            <Box width={[null, null, null, '68rem']} px={1}>
              <Box pt={[4, null, 20]} px={[1, null, null, null, 0]} position={'relative'}>
                <Logo mb={2} position={'absolute'} />
                <Box mt={[10, null, 20]} mb={2}>
                  <Heading as='span' size={['lg', null, 'xl']} width={['60%', null, '100%']} textAlign={'left'}>
                    {intl.formatMessage(MSG.heading)}
                    <Heading
                      as='span'
                      color='brand.500'
                      ml={2}
                      textDecoration={'underline'}
                      size={['lg', null, 'xl']}
                      width={['60%', null, '100%']}
                      textAlign={'left'}
                    >
                      {intl.formatMessage(MSG.today)}
                    </Heading>
                  </Heading>
                </Box>
                <Box display='flex' flexDirection='row' alignItems='center' maxWidth={['100%', null, '575px']} mb={4}>
                  <Button
                    // @ts-ignore
                    color='neutral.500 !important'
                    display='flex'
                    width={['100%', null, '575px']}
                    my={[null, null, 2, 4]}
                    alignItems='center'
                    justifyContent='space-between'
                    bg='neutral.50'
                    _hover={{
                      bg: 'neutral.200',
                      color: 'neutral.900 !important'
                    }}
                    tracking={{
                      guid: 'oEfozSMovpGpJEzuHvPuHN',
                      ui_context: HOME_SEARCH_LANDING_PAGE,
                      descriptor: 'Enter a location button'
                    }}
                    onClick={() => dispatch(toggleListingSearchModal(true))}
                  >
                    <Box display='flex' alignItems='center'>
                      <Icon name='search' />
                      <Text ml={2} as='span'>
                        {intl.formatMessage(MSG.enterLocation)}
                      </Text>
                    </Box>
                  </Button>
                </Box>
                <Card
                  padding={6}
                  display={'flex'}
                  flexDirection={'row'}
                  visibility={['hidden', null, 'visible']}
                  maxWidth='530px'
                  bg='neutral.200'
                >
                  <Box display='flex' alignItems={'center'} mr={4}>
                    <Avatar size={['lg']} name={`${customerFullName} avatar`} src={photoUri} />
                  </Box>
                  <Text color='neutral-900' as='span' mb={0} alignSelf='center'>
                    {firstName && LastName
                      ? intl.formatMessage(MSG.intro, {
                          customerFullName: (
                            <Text as='span' fontWeight='bold'>
                              {customerFullName}
                            </Text>
                          ),
                          companyName: (
                            <Text as='span' fontWeight='bold'>
                              {corporateName}
                            </Text>
                          )
                        })
                      : intl.formatMessage(MSG.introDefault)}
                  </Text>
                </Card>
              </Box>
            </Box>
          </Box>
          <Box
            bg='brand.50'
            width={'100%'}
            borderRadius={[0, '12px']}
            py={4}
            px={2}
            display={['visible', null, 'none']}
          >
            <Card padding={6} display={'flex'} flexDirection={'row'} bg='neutral.200'>
              <Box display='flex' alignItems={'center'} mr={4}>
                <Avatar size={['lg']} name={`${customerFullName} avatar`} src={photoUri} />
              </Box>
              <Text color='neutral-900' as='span' mb={0} alignSelf='center'>
                {firstName && LastName
                  ? intl.formatMessage(MSG.intro, {
                      customerFullName: (
                        <Text as='span' fontWeight='bold'>
                          {customerFullName}
                        </Text>
                      ),
                      companyName: (
                        <Text as='span' fontWeight='bold'>
                          {corporateName}
                        </Text>
                      )
                    })
                  : intl.formatMessage(MSG.introDefault)}
              </Text>
            </Card>
          </Box>
          <Flex
            gap={4}
            my={[6, null, null, 12]}
            px={[2, null, 20]}
            width={'100%'}
            flexDirection={['column', null, null, 'row']}
            justifyContent='center'
          >
            <LandingPageIconCard iconName='message' description={intl.formatMessage(MSG.message)} />
            <LandingPageIconCard iconName='search2-filled' description={intl.formatMessage(MSG.search)} />
            <LandingPageIconCard iconName='wallet-entypo' description={intl.formatMessage(MSG.wallet)} />
          </Flex>
          <Box
            px={4}
            height='220px'
            bg='brand.50'
            backgroundImage={`url(${theme.isLightThemeType ? topographMapLight : topographMapDark})`}
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
          >
            <Text as='b' color='neutral.900' size={['md', null, null, 'xl']} textAlign='center' mb={2}>
              {intl.formatMessage(MSG.mobileCTA)}
            </Text>
            <NativeCTALinks
              tracking={{
                guid: '5UsHbaK7qnphd5N1Gi82RB',
                ui_context: HomeSearchTrackingEventLocation.HOME_SEARCH_LANDING_PAGE
              }}
            />
          </Box>
          <HomeSearchFooter />
        </Box>
      </Box>
    </>
  )
}
