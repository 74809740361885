import { HomeSearchTrackingEventLocation, TrackingEventConstants } from '../../../../constants/tracking'
import { setFilter } from '../../../../actions/filters'
import { defineMessages, useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFilter } from '../../../../store/selectors/filters'
import { useTrackingContext } from '../../../../providers/tracking'
import { Select } from '@homebotapp/hb-react-component-catalog'

export const SORT_VALUES = {
  acresAsc: 'ACRES, asc',
  acresDesc: 'ACRES, desc',
  sqftAsc: 'SQFT_BUILDING, asc',
  sqftDesc: 'SQFT_BUILDING, desc',
  priceAsc: 'LIST_PRICE, asc',
  priceDesc: 'LIST_PRICE, desc',
  daysOnMarket: 'DAYS_ON_MARKET, asc'
}

const LISTINGS_SORT_SELECT = defineMessages({
  daysOnMarket: {
    id: 'HomeSearch.listings.order.daysOnMarket',
    defaultMessage: 'Newest listings'
  },
  priceOptionLowest: {
    id: 'HomeSearch.listings.order.lowest',
    defaultMessage: 'Lowest price'
  },
  priceOptionHighest: {
    id: 'HomeSearch.listings.order.highest',
    defaultMessage: 'Highest price'
  },
  squareFeetOptionSmallest: {
    id: 'HomeSearch.listings.order.smallest',
    defaultMessage: 'Smallest sq. ft.'
  },
  squareFeetOptionLargest: {
    id: 'HomeSearch.listings.order.largest',
    defaultMessage: 'Largest sq. ft.'
  },
  acresOptionSmallest: {
    id: 'HomeSearch.listings.order.smallestAcres',
    defaultMessage: 'Smallest lot size'
  },
  acresOptionLargest: {
    id: 'HomeSearch.listings.order.largestAcres',
    defaultMessage: 'Largest lot size'
  }
})

export const ListingsSort = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const filter = useSelector(selectFilter)
  const { trackingClient } = useTrackingContext()
  const [sortValue, setSortValue] = useState('')

  const onChange = event => {
    const value = event.target.value

    if (value === SORT_VALUES.priceAsc) {
      trackingClient?.searchSort({
        search_sort_updated: TrackingEventConstants.SORT_LISTINGS_BY_PRICE_HIGH
      })
    } else if (value === SORT_VALUES.priceDesc) {
      trackingClient?.searchSort({
        search_sort_updated: TrackingEventConstants.SORT_LISTINGS_BY_PRICE_LOW
      })
    } else if (value === SORT_VALUES.sqftAsc) {
      trackingClient?.searchSort({
        search_sort_updated: TrackingEventConstants.SORT_LISTINGS_BY_SQFT_LARGE
      })
    } else if (value === SORT_VALUES.sqftDesc) {
      trackingClient?.searchSort({
        search_sort_updated: TrackingEventConstants.SORT_LISTINGS_BY_SQFT_SMALL
      })
    } else if (value === SORT_VALUES.acresAsc) {
      trackingClient?.searchSort({
        search_sort_updated: TrackingEventConstants.SORT_LISTINGS_BY_ACRES_LARGE
      })
    } else if (value === SORT_VALUES.acresDesc) {
      trackingClient?.searchSort({
        search_sort_updated: TrackingEventConstants.SORT_LISTINGS_BY_ACRES_SMALL
      })
    } else if (value === SORT_VALUES.daysOnMarket) {
      trackingClient?.searchSort({
        search_sort_updated: TrackingEventConstants.SORT_LISTINGS_BY_DAYS_ON_MARKET
      })
    }

    dispatch(setFilter({ order: value }))
  }

  useEffect(() => {
    if (filter?.order) {
      setSortValue(filter?.order)
    }
  }, [filter?.order])

  return (
    <Select
      size='sm'
      width='unset'
      cursor='pointer'
      value={sortValue}
      name='listings-sort'
      data-qa='listingsSort'
      onChange={event => onChange(event)}
      tracking={{
        guid: 'bxVRu8GqvrTagQU4EDAtb8',
        ui_context: HomeSearchTrackingEventLocation.HOME_SEARCH_LISTINGS_INDEX_PAGE_LIST_VIEW,
        descriptor: 'Listings Sort Dropdown'
      }}
    >
      <option value={SORT_VALUES.daysOnMarket}>{intl.formatMessage(LISTINGS_SORT_SELECT.daysOnMarket)}</option>
      <option value={SORT_VALUES.priceAsc}>{intl.formatMessage(LISTINGS_SORT_SELECT.priceOptionLowest)}</option>
      <option value={SORT_VALUES.priceDesc}>{intl.formatMessage(LISTINGS_SORT_SELECT.priceOptionHighest)}</option>
      <option value={SORT_VALUES.sqftAsc}>{intl.formatMessage(LISTINGS_SORT_SELECT.squareFeetOptionSmallest)}</option>
      <option value={SORT_VALUES.sqftDesc}>{intl.formatMessage(LISTINGS_SORT_SELECT.squareFeetOptionLargest)}</option>
      <option value={SORT_VALUES.acresAsc}>{intl.formatMessage(LISTINGS_SORT_SELECT.acresOptionSmallest)}</option>
      <option value={SORT_VALUES.acresDesc}>{intl.formatMessage(LISTINGS_SORT_SELECT.acresOptionLargest)}</option>
    </Select>
  )
}
