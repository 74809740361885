import {
  Box,
  Image,
  Text,
  Card,
  CardBody,
  Flex,
  Stack,
  Heading,
  LinkOverlay,
  ComponentTrackingType
} from '@homebotapp/hb-react-component-catalog'
import { ListingFavoriteBtn } from '../ListingFavoriteBtn/ListingFavoriteBtn'
import { ListingPriceChangeTag } from '../ListingPriceChangeTag/ListingPriceChangeTag'
import { formatCityStateZip, formatStreetAddress } from '../../../helpers/formatListingAddress'
import { formatToCurrency } from '../Utils/format'
import { useMemo } from 'react'
import { ListingStatusNew } from '../ListingStatusNew/ListingStatusNew'
import { Listing, ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import history from '../../../history'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomerId } from '../../../store/selectors/customerProfile'
import { formatNumValue } from '../../../helpers/utils'
import { setPreviouslyViewedListingId } from '../../../actions/listings'
import { useAppSelector } from '../../../store/hooks'
import styles from './ListingCardNew.module.scss'
import { selectPreviouslyViewedListingId, selectSelectedListingId } from '../../../store/selectors/listings'
import { sqftToAcres } from '../../../helpers/converters'
import { GoodValueTag } from '../GoodValueTag/GoodValueTag'
import { HEADER_ATTRIBUTION_MLS } from '../ListingDetails/StatusHeader/StatusHeader'
import { ListingAssumableRatePill } from '../ListingPill'
import { ListingStatusEnum } from '../../../types/listingStatus'
import { ListingSoldDateTag } from '../ListingSoldDateTag/ListingSoldDateTag'

export type TagPreferenceType = 'assumableRate' | 'priceChange' | 'goodValue' | 'soldDate'

export interface ListingCardNewProps {
  listing: ListingCompact | Listing
  /** optional; allows for impersonation of favorite listings */
  clientId?: string
  tracking?: ComponentTrackingType
  setShowLeadsModal?: (show: boolean) => void
  tagPreference?: TagPreferenceType
}

const MSG = defineMessages({
  beds: {
    id: 'General.beds',
    defaultMessage: 'beds'
  },
  baths: {
    id: 'General.baths',
    defaultMessage: 'baths'
  },
  sqft: {
    id: 'Gallery.sqft',
    defaultMessage: 'sqft'
  },
  acres: {
    id: 'Gallery.acres',
    defaultMessage: 'acres'
  },
  lot: {
    id: 'General.lot',
    defaultMessage: 'lot'
  },
  alt: {
    id: 'HomeSearch.listingCardNew.mlsLogo.alt',
    defaultMessage: '{name} Logo'
  }
})

/**
 * Tag Preference Explained:
 *
 * ListingCardNew is a reusable component that is used to display a listing card
 * in the home search page as well as the feeds
 *
 * We want to highlight tags on the listing card based on the type of feed
 *
 * After the preference is honored, the tags are displayed in the following order and limited to two:
 *
 * 1. Price Change
 * 2. Assumable Rate
 * 3. Good Value (Concessions)
 */

export const getPreferredTags = (
  hasPriceChanged: boolean,
  listing: ListingCompact | Listing,
  tagPreference?: TagPreferenceType
) => {
  const tags: JSX.Element[] = []

  if (tagPreference === 'soldDate') {
    tags.push(<ListingSoldDateTag key='soldDate' soldDate={listing?.closeDate} />)
  } else if (tagPreference === 'priceChange') {
    tags.push(<ListingPriceChangeTag key='priceChange' listing={listing} />)
  } else if (tagPreference === 'assumableRate' && listing?.assumableRatePercent) {
    tags.push(<ListingAssumableRatePill key='assumableRate' listing={listing} />)
  } else if (tagPreference === 'goodValue' && listing?.hasSellerConcession) {
    tags.push(<GoodValueTag key='goodValue' listing={listing} />)
  }

  if (listing?.closeDate && listing?.status === ListingStatusEnum.CLOSED && !tags.some(tag => tag.key === 'soldDate')) {
    tags.push(<ListingSoldDateTag key='soldDate' soldDate={listing?.closeDate} />)
  }
  if (hasPriceChanged && !tags.some(tag => tag.key === 'priceChange')) {
    tags.push(<ListingPriceChangeTag key='priceChange' listing={listing} />)
  }
  if (listing?.assumableRatePercent && !tags.some(tag => tag.key === 'assumableRate')) {
    tags.push(<ListingAssumableRatePill key='assumableRate' listing={listing} />)
  }
  if (listing?.hasSellerConcession && !tags.some(tag => tag.key === 'goodValue')) {
    tags.push(<GoodValueTag key='goodValue' listing={listing} />)
  }

  return tags.slice(0, 2)
}

export const ListingCardNew = ({
  listing,
  clientId,
  tracking,
  setShowLeadsModal,
  tagPreference
}: ListingCardNewProps) => {
  const intl = useIntl()
  const selectedListingId = useSelector(selectSelectedListingId)
  const previouslyViewedListingId = useSelector(selectPreviouslyViewedListingId)
  const isPublic = useAppSelector(state => state.auth.isPublic)
  const isLead = useAppSelector(state => state.auth.isLead)
  const bedroomsCount = useMemo(() => listing.bedroomsCount ?? 0, [listing.bedroomsCount])
  const bathroomsCount = useMemo(() => listing.bathroomsCount ?? 0, [listing.bathroomsCount])
  const hasPriceChanged = useMemo(
    () => listing?.previousListPriceCents !== listing?.priceCents,
    [listing?.previousListPriceCents, listing?.priceCents]
  )

  const propertySize = useMemo(() => {
    if (listing.sqftTotal) {
      return `${formatNumValue(Math.round(listing.sqftTotal).toString())} ${intl.formatMessage(MSG.sqft)}`
    } else if (listing.sqftLot) {
      return `${sqftToAcres(listing.sqftLot).toFixed(2)} ${intl.formatMessage(MSG.acres)}`
    }

    return undefined
  }, [listing.sqftLot, listing.sqftTotal])

  const customerProfileId = useSelector(selectCustomerId)

  const dispatch = useDispatch()

  const navigateToListing = () => {
    if (isLead && setShowLeadsModal) {
      setShowLeadsModal(true)
    } else {
      dispatch(setPreviouslyViewedListingId(listing.id))
      history.push(`/home-search/${isPublic ? 'public' : customerProfileId}/listing/${listing.id}`)
    }
  }

  const preferredTags = useMemo(
    () => getPreferredTags(hasPriceChanged, listing, tagPreference),
    [tagPreference, listing, hasPriceChanged]
  )

  return (
    <Card
      as='button'
      _hover={{
        transform: 'scale(1.025)',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #444',
        transition: 'all 0.2s ease-in-out'
      }}
      className={
        selectedListingId === listing?.id || previouslyViewedListingId === listing?.id ? styles.selected : 'unset'
      }
      id={listing?.id}
      cursor='pointer'
      width='100%'
      borderRadius={16}
      overflow='hidden'
      backgroundColor='neutral.300'
      onClick={navigateToListing}
    >
      <LinkOverlay w='100%' tracking={tracking}>
        <CardBody p={0} width='100%'>
          <Flex position='absolute' zIndex={1} left={2} top={2} gap={1}>
            {preferredTags}
          </Flex>
          {!isLead && (
            <Flex position='absolute' zIndex={1} right={2} top={2}>
              <ListingFavoriteBtn listingId={listing?.id} clientId={clientId} />
            </Flex>
          )}
          <Image
            loading='lazy'
            width='100%'
            objectFit='cover'
            minHeight='200px'
            maxHeight='200px'
            overflow={'hidden'}
            src={listing?.thumbnailImageFileNames?.[0] as string}
            alt={`Listing 123`}
          />
          {listing?.mlsLogo && (
            <Image
              top='160px'
              right='8px'
              maxHeight={8}
              borderRadius={4}
              position='absolute'
              src={listing.mlsLogo}
              // @ts-ignore
              backgroundColor='var(--hb-colors-whiteAlpha-700)'
              alt={intl.formatMessage(MSG.alt, { name: listing.originatingSystemName })}
            />
          )}
        </CardBody>
        <Box px={3} mt='2' width='100%'>
          {listing?.originatingSystemName && HEADER_ATTRIBUTION_MLS.includes(listing.originatingSystemName) && (
            <Text size='3xs' mb={0}>
              {listing?.brokerage?.name && listing?.brokerage?.name}
              {listing?.brokerage?.name && listing?.buyerBrokerageName && ' • '}
              {listing?.buyerBrokerageName && listing?.buyerBrokerageName}
            </Text>
          )}
          <Flex justifyContent='space-between' alignItems='center' direction='row'>
            <Heading size='md'>
              {formatToCurrency(
                (listing?.status === ListingStatusEnum.CLOSED && listing?.closePriceCents
                  ? listing?.closePriceCents
                  : listing.priceCents) / 100
              )}
            </Heading>
            <Flex alignItems='center' mt={-4}>
              <ListingStatusNew listing={listing} />
            </Flex>
          </Flex>

          <Box mt={-4}>
            <Stack direction='row' spacing={2} alignItems='center' p={0}>
              {bedroomsCount > 0 && (
                <Text key={MSG.beds.id} mb={1} size='xs' textColor='neutral.900' fontWeight={600}>
                  {bedroomsCount} <FormattedMessage id={MSG.beds.id} defaultMessage={MSG.beds.defaultMessage} />
                </Text>
              )}
              {bathroomsCount > 0 && (
                <Text key={MSG.baths.id} mb={1} size='xs' textColor='neutral.900' fontWeight={600}>
                  {bathroomsCount} <FormattedMessage id={MSG.baths.id} defaultMessage={MSG.baths.defaultMessage} />
                </Text>
              )}
              {propertySize && (
                <Text key={MSG.sqft.id} mb={1} size='xs' textColor='neutral.900' fontWeight={600}>
                  {propertySize}
                </Text>
              )}
            </Stack>
            {listing?.internetAddressDisplayYn !== false && (
              <>
                <Text noOfLines={1} textColor='neutral.800' size='sm' mb={-1} textAlign='left'>
                  {formatStreetAddress(listing?.address)}
                </Text>
                <Text noOfLines={1} textColor='neutral.800' size='sm' mb={1} textAlign='left'>
                  {formatCityStateZip(listing?.address)}
                </Text>
              </>
            )}
            <Text size='3xs' textAlign='left' textColor='neutral.600'>
              {listing?.originatingSystemName} • #{listing?.mlsNumber}
            </Text>
          </Box>
        </Box>
      </LinkOverlay>
    </Card>
  )
}
