import { useState } from 'react'
import { App } from './App'
import { Provider } from 'react-redux'
import store from '../store'
import { CustomIntlProvider } from '../providers/intl'
import { Router } from 'react-router-dom'
import history from '../history'
import { ErrorBoundary } from './ErrorBoundary'
import { TrackingProvider } from '../providers/tracking'
import { legacyThemes, LEGACY_THEME_NAMES } from '@homebotapp/hb-react-component-catalog'
import { InjectTrackingIntoSwashbuckle } from '../providers/injectTrackingIntoSwashbuckle'

export const AppContainer = () => {
  const [isError, setIsError] = useState(false)

  let initialThemeName: string
  try {
    initialThemeName = localStorage?.getItem('LOADING_THEME') ?? LEGACY_THEME_NAMES.HB_DEFAULT
  } catch (e) {
    initialThemeName = LEGACY_THEME_NAMES.HB_DEFAULT
  }
  const initialTheme = legacyThemes[initialThemeName]

  return (
    <ErrorBoundary setIsError={setIsError}>
      <Provider store={store}>
        {/* We have to pass in a theme here or else Chakra will
          set the wrong mode in local storage and mess things up
        */}
        <CustomIntlProvider>
          <TrackingProvider>
            <InjectTrackingIntoSwashbuckle theme={initialTheme}>
              <Router history={history}>
                <App error={isError} />
              </Router>
            </InjectTrackingIntoSwashbuckle>
          </TrackingProvider>
        </CustomIntlProvider>
      </Provider>
    </ErrorBoundary>
  )
}
