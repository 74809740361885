import produce from 'immer'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  SET_CLIENT_FETCH_ID,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_SUCCESS,
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_HOMES_REQUEST,
  FETCH_CLIENT_HOMES_SUCCESS,
  FETCH_CLIENT_HOMES_FAILURE,
  SAVE_CLIENT_ANSWER_SUCCESS,
  SAVE_CLIENT_ANSWER_FAILURE,
  SAVE_CLIENT_DATA_REQUEST,
  SAVE_CLIENT_DATA_SUCCESS,
  SAVE_CLIENT_DATA_FAILURE,
  RESET_CLIENT_STATUS,
  FLAG_FOR_UNSUBSCRIBE,
  SET_CLIENT_IS_GUEST,
  SET_CLIENT_IS_LOADING
} from '../actions/actionTypes'
import { clientState } from '../factories/client'

export const initialState = clientState()

export default (state = initialState, { id, type, data, error, saveRequest, resource }) =>
  produce(state, draft => {
    const updateAnswer = ({ id, question, response }) => {
      const answer = draft.data.clientAnswers.find(({ id: i }) => i === id)

      if (answer) {
        Object.assign(answer, { question, response })
      } else {
        draft.data.clientAnswers.push({
          id,
          question,
          response
        })
      }
    }

    switch (type) {
      case SET_CLIENT_FETCH_ID:
        if (id !== draft.data.id) {
          Object.assign(draft, {
            ...initialState,
            flagForUnsubscribe: draft.flagForUnsubscribe
          })
        }
        draft.fetchId = id
        break

      case FETCH_CLIENT_REQUEST:
        draft.hasRequestedClient = true
        draft.error = null
        break

      case FETCH_CLIENT_HOMES_REQUEST:
        draft.hasRequestedHomes = true
        break

      case FETCH_CLIENT_SUCCESS:
        draft.loadingData = false
        if (data.id === draft.fetchId) {
          const combindedData = { ...draft.data, ...data }
          draft.data = combindedData
        }
        break

      case FETCH_CLIENT_HOMES_SUCCESS: {
        const mappedData = map(data, home => {
          const id = get(home, 'legacyRef')
          const uuid = get(home, 'id')
          const street = get(home, 'addressStreet')
          const zip = get(home, 'addressZip')
          const unit = get(home, 'addressUnit')

          return {
            id,
            uuid,
            address: {
              street,
              zip,
              unit
            }
          }
        })
        draft.loadingHomes = false
        draft.data.homes = mappedData
        break
      }

      case SET_CLIENT_IS_GUEST:
        draft.isGuest = data
        break
      case SET_CLIENT_IS_LOADING:
        draft.loadingData = data
        break

      case FETCH_CLIENT_FAILURE:
        draft.loadingData = false
        draft.error = error
        if (get(error, 'response.status') === 404) {
          Object.assign(draft, initialState)
          draft.fetchId = 'not-found'
        }
        break

      case FETCH_CLIENT_HOMES_FAILURE:
        draft.loadingHomes = false
        draft.error = error
        break

      case SAVE_CLIENT_ANSWER_SUCCESS:
        updateAnswer(data)
        break

      case SAVE_CLIENT_ANSWER_FAILURE:
        draft.error = error
        break

      case SAVE_CLIENT_DATA_REQUEST:
        draft.saveRequest = saveRequest
        draft.saveSuccess = null
        break

      case SAVE_CLIENT_DATA_SUCCESS:
        if (data.id === draft.fetchId) {
          const combindedData = { ...draft.data, ...data }
          draft.data = combindedData
        }
        draft.saveSuccess = true
        draft.saveRequest = null
        draft.saveError = null
        break

      case SAVE_CLIENT_DATA_FAILURE:
        draft.saveError = error
        draft.saveSuccess = false
        draft.saveRequest = null
        break

      case RESET_CLIENT_STATUS:
        draft.saveRequest = null
        draft.saveSuccess = null
        draft.saveError = null
        draft.flagForUnsubscribe = null
        break

      case FLAG_FOR_UNSUBSCRIBE:
        draft.flagForUnsubscribe = resource
        break

      default:
        break
    }
  })
