/* eslint-disable prettier/prettier */
// PLACE ALL HOME SEARCH RELATED TRACKING EVENTS HERE

export const HOME_SEARCH_FOOTER = 'home_search_footer'
export const HOME_SEARCH_LISTING_DETAILS = 'home_search_listing_details'
export const HOME_SEARCH_LISTING_DETAILS_ESTIMATED_MONTHLY_PAYMENT = 'home_search_listing_details_estimated_monthly_payment'
export const HOME_SEARCH_LISTING_DETAILS_SMART_MOVE = 'home_search_listing_details_smart_move'
export const HOME_SEARCH_CUSTOM_MODULE = 'home_search_custom_module'
export const HOME_SEARCH_LISTING_DETAILS_FOOTER = 'home_search_listing_details_footer'
export const HOME_SEARCH_LISTING_DETAILS_NATIVE_APP_CTA = 'home_search_listing_details_native_app_cta'
export const HOME_SEARCH_LISTINGS_FOOTER = 'home_search_listings_footer'
export const HOME_SEARCH_NATIVE_APP_CTA = 'home_search_native_app_cta'
export const HOME_SEARCH_PASSWORD_GATE = 'home_search_password_gate'
export const HOME_SEARCH_FAVORITES_BUTTON = 'home_search_favorites_button'
export const HOME_SEARCH_FAVORITE_LISTINGS_PAGE = 'home_search_favorite_listings_page'
export const HOME_SEARCH_FOR_YOU_PAGE = 'home_search_for_you_page'
export const HOME_SEARCH_FOR_YOU_INDIVIDUAL_GALLERY_PAGE = 'home_search_for_you_individual_gallery_page'
export const HOME_SEARCH_SAVED_SEARCHES_BUTTON = 'home_search_saved_searches_button'
export const HOME_SEARCH_CREATE_SAVED_SEARCH_BUTTON = 'home_search_create_saved_search_button'
export const HOME_SEARCH_REMOVE_SAVED_SEARCH_MODAL_BUTTON = 'home_search_remove_saved_search_modal_button'
export const HOME_SEARCH_SAVED_SEARCH_PAGE_DELETE = 'home_search_saved_search_page_delete'
export const HOME_SEARCH_SAVED_SEARCH_PAGE_SEE_MORE_LISTINGS = 'home_search_saved_search_page_see_more_listings'
export const HOME_SEARCH_DISMISS_CREATE_SAVED_SEARCH_MODAL = 'home_search_dismiss_create_saved_search_modal'
export const HOME_SEARCH_DISMISS_REMOVE_SAVED_SEARCH_MODAL = 'home_search_dismiss_remove_saved_search_modal'
export const HOME_SEARCH_LISTINGS_INDEX_PAGE_LIST_VIEW = 'home_search_listings_index_page_list_view'
export const HOME_SEARCH_LISTINGS_FILTER = 'home_search_listings_filter'
export const HOME_SEARCH_LANDING_PAGE = 'home_search_landing_page'
