import { Flex, Icon, IconButton } from '@homebotapp/hb-react-component-catalog'
import { useDispatch, useSelector } from 'react-redux'
import { selectClientIsGuest } from '../../../store/selectors/client'
import { useGetFavoriteListings } from '../../../hooks/gallery/useGetFavoriteListings'
import { useMemo } from 'react'
import { useToggleFavoriteListing } from '../../../hooks/listings/useToggleFavoriteListing'
import { isAuthTokenSupported } from '../../../auth'
import { toggleAuthModal } from '../../../actions/homeSearchAuthModal'

export interface ListingFavoriteBtnProps {
  listingId: string
  /** optional for impersonation */
  clientId?: string
}

export const ListingFavoriteBtn = ({ listingId, clientId }: ListingFavoriteBtnProps) => {
  /** true if impersonating */
  const isGuest = useSelector(selectClientIsGuest)

  const { data, refetch } = useGetFavoriteListings({ clientId })
  const favoriteListings = useMemo(() => data?.favoriteListings?.listings ?? [], [data?.favoriteListings?.listings])

  const { mutateAsync: toggleFavoriteListing, isLoading } = useToggleFavoriteListing()
  const dispatch = useDispatch()

  const isFavorite = useMemo(() => {
    return favoriteListings.some(listing => listing?.id === listingId)
  }, [favoriteListings, listingId])

  const handleFavoriteClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!isAuthTokenSupported() || isGuest) {
      dispatch(toggleAuthModal(true))
      return
    }
    await toggleFavoriteListing({ listingId })
    refetch()
  }

  if (isGuest) {
    return null
  }

  return (
    <Flex alignItems='center'>
      {!isLoading && (
        <IconButton
          size='sm'
          variant='outline'
          colorScheme='secondary'
          aria-label='Like this listing'
          bg='blackAlpha.700'
          _hover={{
            bg: 'blackAlpha.900',
            _light: {
              bg: 'whiteAlpha.900'
            }
          }}
          _light={{ bg: 'whiteAlpha.700' }}
          onClick={handleFavoriteClick}
          icon={
            isFavorite ? <Icon name='heart' color='brand.700' h={4} w={4} /> : <Icon name='heart-empty' h={4} w={4} />
          }
        />
      )}
    </Flex>
  )
}
