import { UseQueryOptions } from 'react-query'
import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { QueryKey } from '../../constants/queryKey'
import { graphqlGLClient } from '../../api/gqlaxy/gql/queries/graphQLClient'
import {
  ListingCompact,
  ListingsForAreaQuery,
  ListingsForAreaQueryVariables
} from '../../api/gqlaxy/gql/generated/graphql'
import { useListingsForAreaQuery } from '../../api/gqlaxy/gql/generated/graphql-hooks'
import { useSelector } from 'react-redux'
import { selectFilter } from '../../store/selectors/filters'
import { useFilters } from '../../Components/homeSearch/Listings/hook/useFilters'
import { useAppSelector } from '../../store/hooks'

interface ErrorPrompt {
  error: string
  message: string
}

interface Option<D, E> extends UseQueryOptions<D, E> {
  disableErrorPrompts?: boolean
  errorPrompts?: ErrorPrompt[]
}

export type QueryListingsForAreaData = {
  hasMore: boolean
  listings: ListingCompact[]
  page: number
}

export const useGetListingsForArea = (
  params: ListingsForAreaQueryVariables,
  option: Option<ListingsForAreaQuery, Error> = {}
) => {
  const toast = useToast()

  const filter = useSelector(selectFilter)

  const { setPage } = useFilters()

  const isPublic = useAppSelector(state => state.auth.isPublic)

  const [listings, setListings] = useState<ListingCompact[]>([])

  const [requesting, setIsRequesting] = useState(true)

  const client = graphqlGLClient({
    // endpoint: 'http://127.0.0.1:5001/mobile-staging-376418/us-central1/gqlactus' // local endpoint
  })

  const { disableErrorPrompts, errorPrompts, enabled, ...options } = option

  const handleError = useCallback(
    (...args) => {
      const [e] = args

      if (disableErrorPrompts) return

      const prompt = errorPrompts?.find(({ error }) => error === e.message)
      const message = prompt?.message ?? e.message

      toast({
        description: message,
        status: 'error',
        duration: 4000,
        position: 'top-right'
      })
    },
    [disableErrorPrompts, errorPrompts, toast]
  )

  const { data, refetch, isLoading, isFetching } = useListingsForAreaQuery(
    client,
    { ...params, isPublic } as ListingsForAreaQueryVariables,
    {
      queryKey: [QueryKey.GetListingsForArea, params?.searchArea?.boundingBox],
      onError: handleError,
      staleTime: Infinity,
      keepPreviousData: true,
      select: data => {
        return data
      },
      onSuccess: () => {
        setIsRequesting(false)
      },
      enabled,
      ...options
    }
  )

  useEffect(() => {
    if (data && data.listingsForArea) {
      const { page, listings: newListings } = data.listingsForArea
      if (page > 0) {
        setListings(prevListings => [...prevListings, ...newListings])
        setPage(data.listingsForArea.page)
      } else {
        setListings(newListings)
        setPage(0)
      }
    }
  }, [data])

  useEffect(() => {
    if (!enabled || !filter?.displayName) {
      return
    }
    refetchListings()
  }, [params.page, params.filters?.monthlyExpensesInput, params.sortOrder])

  const refetchListings = () => {
    setIsRequesting(true)
    setPage(0)
    refetch()
  }

  return {
    data: listings,
    isLoading,
    isFetching: isFetching || requesting,
    refetchListings,
    hasMore: data?.listingsForArea?.hasMore ?? false
  }
}
