import { useEffect, createContext, useContext, useState, useRef } from 'react'
import noop from 'lodash/noop'
import { Experiment } from '@amplitude/experiment-js-client'
import qs from 'qs'
import { getBearerToken } from '../../auth'
import {
  getUserIdFromToken,
  getUserEmailFromToken,
  getUserTypeFromToken,
  ClientsTrackingClient,
  ClientsTrackingTypes
} from '../../quartermaster/src'
import { buildUrlParamObj } from '../../helpers/utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectClientId,
  selectClientIsBuyer,
  selectClientIsGuest,
  selectClientIsLoadingData,
  selectIsHomeowner
} from '../../store/selectors/client'
import {
  selectCorporateProfileId,
  selectCustomerEmail,
  selectCustomerId,
  selectCustomerIsInsuranceAgent,
  selectCustomerIsLoanOfficer,
  selectCustomerIsRealEstateAgent,
  selectCustomerProfileLoading
} from '../../store/selectors/customerProfile'
import { selectAuthedAsClient } from '../../store/selectors/auth'
import { RootState } from '../../types/rootState'
import { setHasSyncedDataWithTrackingClient } from '../../store/slices/auth'
import { isProductionEnvironment } from '../../helpers/environment'
import { useAppSelector } from '../../store/hooks'
import { AUTH_USER_TYPES } from '../../quartermaster/src/auth/constants'

const amplitudeClientsDeploymentKey = process.env.REACT_APP_AMPLITUDE_DEPLOYMENT as string
const REACT_APP_AMPLITUDE_TRACKING_KEY = process.env.REACT_APP_AMPLITUDE_TRACKING_KEY as string

type ClientsTrackingContextTypes = ClientsTrackingTypes.TrackingContextTypes

const initialTrackingContext: ClientsTrackingContextTypes = {
  trackingClient: undefined,
  experiment: undefined,
  debouncing: new Set(),
  setDebouncing: noop
}

const environment = isProductionEnvironment ? 'production' : 'development'

ClientsTrackingClient.load({
  client: {
    apiKey: REACT_APP_AMPLITUDE_TRACKING_KEY,
    configuration: {
      // Conditionally use the amplitude proxy endpoint in production environment
      ...(isProductionEnvironment && {
        serverUrl: 'https://35y3mzah83.execute-api.us-east-1.amazonaws.com/apv1/2/httpapi'
      })
    }
  }
})

const TrackingContext = createContext(initialTrackingContext)

const TrackingProvider = ({ children }: ClientsTrackingTypes.TrackingProviderProps) => {
  const dispatch = useDispatch()

  const isLead = useAppSelector(state => state.auth.isLead)

  const tokenRef = useRef('')
  const utmParamsRef = useRef({})
  const queuedAmplitudeEventsRef = useRef<(() => void)[]>([])
  const QUEUE_BUFFER_LIMIT = 20

  const [experiment, setExperiment] = useState(initialTrackingContext.experiment)

  const [debouncing, setDebouncing] = useState(initialTrackingContext.debouncing)

  /* We have to immediately fetch the token from the auth callback url and send to amplitude
    to fix a race condition where the token in cookies is not set in time for the first event to be sent
  */
  const { access_token: authCallBackAccessToken = null } = window.location.hash
    ? buildUrlParamObj(window.location.hash, '#')
    : {}
  tokenRef.current = authCallBackAccessToken || getBearerToken()

  /* We have to immediately fetch the utm params from the auth callback url and send to amplitude
    to fix a race condition where the utm params need to be sent with the identify function
  */
  const { search } = window.location
  const { utm_content, utm_source, utm_medium, utm_campaign } = qs.parse(search, { ignoreQueryPrefix: true })
  if (utm_campaign) {
    utmParamsRef.current = {
      utm_content,
      utm_source,
      utm_medium,
      utm_campaign
    }
  }

  // Client/Customer data from JWT
  const userId = tokenRef.current ? getUserIdFromToken(tokenRef.current) : ''
  const userEmail = getUserEmailFromToken(tokenRef.current)
  const userType = getUserTypeFromToken(tokenRef.current)

  // Client/Customer data from Api calls
  const clientId = useSelector(selectClientId) as string
  const customerId = useSelector(selectCustomerId)
  const customerEmail = useSelector(selectCustomerEmail)
  const corporateProfileId = useSelector(selectCorporateProfileId)
  const isClient = useSelector(selectAuthedAsClient)
  const isLoanOfficer = useSelector(selectCustomerIsLoanOfficer) && !isClient
  const isRealEstateAgent = useSelector(selectCustomerIsRealEstateAgent) && !isClient
  const isInsuranceAgent = useSelector(selectCustomerIsInsuranceAgent) && !isClient
  const isHomeowner = useSelector(selectIsHomeowner) && isClient
  const isBuyer = useSelector(selectClientIsBuyer) && isClient
  const isGuest = useSelector(selectClientIsGuest)
  const isClientAGuest = useSelector(selectClientIsGuest) && isClient

  const customerProfileDataIsLoading = useSelector(selectCustomerProfileLoading)
  const clientAmplitudeDataIsLoading = useSelector(selectClientIsLoadingData)
  const hasSyncedDataWithTrackingClient = useSelector<RootState, boolean>(
    state => state.auth.hasSyncedDataWithTrackingClient
  )

  useEffect(() => {
    const initializeAmplitudeUser = async () => {
      const exp = Experiment.initializeWithAmplitudeAnalytics(amplitudeClientsDeploymentKey)

      setExperiment(exp)

      /* For Search landing page, we do not have a user, user considered guest */
      /* only fire the function identify once */
      if (isLead && !customerProfileDataIsLoading && !hasSyncedDataWithTrackingClient) {
        const customerFields = {
          customer_id: customerId,
          customer_email_address: customerEmail
        }

        const userFields = {
          environment,
          user_type: AUTH_USER_TYPES.UNAUTHED,
          is_lead: isLead,
          corp_id: corporateProfileId,
          is_loan_officer: isLoanOfficer,
          is_insurance_agent: isInsuranceAgent,
          is_real_estate_agent: isRealEstateAgent,
          is_homeowner: isHomeowner,
          is_buyer: isBuyer,
          is_guest: isGuest,
          ...customerFields
        }
        await ClientsTrackingClient.identify(undefined, userFields).promise.then(async () => {
          dispatch(setHasSyncedDataWithTrackingClient(true))
          await exp.fetch({ user_id: userId })
        })
      }

      /* Only fire identify when the data is all available and only fire the function identify once */
      if (!clientAmplitudeDataIsLoading && !hasSyncedDataWithTrackingClient && !isLead) {
        const jwtUserFields = {
          user_type: userType,
          email_address: userEmail,
          email_domain: userEmail.replace(/^.+?@(.*?)/, '$1'),
          email_tag: userEmail.indexOf('+') > -1 ? userEmail.replace(/^.+?\+([^+@]*)@.+$/, '$1') : ''
        }

        const mikasaUserFields = {
          client_id: clientId,
          customer_id: customerId,
          corp_id: corporateProfileId,
          is_loan_officer: isLoanOfficer,
          is_insurance_agent: isInsuranceAgent,
          is_real_estate_agent: isRealEstateAgent,
          is_homeowner: isHomeowner,
          is_buyer: isBuyer,
          is_guest: isClientAGuest
        }

        const authCallBackUtmFields = {
          ...utmParamsRef.current
        }

        const userFields = {
          environment,
          ...jwtUserFields,
          ...mikasaUserFields,
          ...authCallBackUtmFields
        }

        await ClientsTrackingClient.identify(userId, userFields).promise.then(async () => {
          dispatch(setHasSyncedDataWithTrackingClient(true))
          await exp.fetch({ user_id: userId })
        })
      }
    }

    initializeAmplitudeUser()

    return () => {
      setDebouncing(new Set<() => void>())
    }
  }, [
    isLead,
    clientAmplitudeDataIsLoading,
    clientId,
    corporateProfileId,
    customerId,
    isBuyer,
    isGuest,
    isHomeowner,
    isInsuranceAgent,
    isLoanOfficer,
    isRealEstateAgent,
    userEmail,
    userId,
    userType,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    dispatch,
    hasSyncedDataWithTrackingClient
  ])

  const amplitudeEventsThatShouldNotBeProxied = ['amplitude', 'isInitializedAndEnabled', 'disabled', 'toJSON', 'track']

  const handler = {
    get(target, property) {
      if (amplitudeEventsThatShouldNotBeProxied.includes(property)) {
        return target[property]
      }

      // Proxy the individual amplitude events (ie moduleViewed etc)
      return new Proxy(target[property], recursiveHandler)
    }
  }

  const recursiveHandler = {
    apply: function (target, thisArg, argumentsList) {
      if (
        hasSyncedDataWithTrackingClient ||
        queuedAmplitudeEventsRef.current.length > QUEUE_BUFFER_LIMIT // in case identify function fails, we eventually send data to Amplitude
      ) {
        target.apply(thisArg, argumentsList)
      } else {
        queuedAmplitudeEventsRef.current.push(() => {
          target.apply(thisArg, argumentsList)
        })
      }
    }
  }

  /* We have to wait until the user identify function fires before we send any events to Amplitude
    We use JS proxies to accomplish this - this prevents events being fired where there are no user properties associated
  */
  const trackingClientWithQueue = new Proxy(ClientsTrackingClient, handler)

  useEffect(() => {
    if (hasSyncedDataWithTrackingClient) {
      // Fire all the individual queued Amplitude events once loading is complete
      queuedAmplitudeEventsRef.current.forEach(event => event())
      queuedAmplitudeEventsRef.current = []
    }
  }, [hasSyncedDataWithTrackingClient])

  return (
    <TrackingContext.Provider
      value={{
        trackingClient: trackingClientWithQueue,
        experiment,
        ampliUserId: userId,
        debouncing,
        setDebouncing
      }}
    >
      {children}
    </TrackingContext.Provider>
  )
}

const useTrackingContext = () => {
  const context = useContext(TrackingContext)

  if (!context) {
    throw new Error('useTrackingContext must be used as a child of TrackingProvider')
  }

  return { ...context }
}

export { TrackingProvider, useTrackingContext }
