import { RefObject } from 'react'
import { SearchModalInput } from './SearchModalInput/SearchModalInput'
import { SearchModalResults } from './SearchModalResults'
import { ListingSearchProvider } from '../../../../context/listingSearch'

import styles from './SearchModal.module.scss'

interface SearchModalProps {
  locationSearchInputRef?: RefObject<HTMLInputElement> | null | undefined
}

export const SearchModal = ({ locationSearchInputRef = null }: SearchModalProps) => (
  <ListingSearchProvider>
    <div className={styles.container} data-qa='search-modal'>
      <SearchModalInput locationSearchInputRef={locationSearchInputRef} />
      <SearchModalResults locationSearchInputRef={locationSearchInputRef} />
    </div>
  </ListingSearchProvider>
)
