import { defineMessages, useIntl } from 'react-intl'
import { Flex, Text, Icon } from '@homebotapp/hb-react-component-catalog'
import { Listing, ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'

export const MSG = defineMessages({
  goodValue: {
    id: 'Listings.listing.goodValue',
    defaultMessage: 'Good value'
  },
  assumable: {
    id: 'Listings.listing.assumable',
    defaultMessage: 'Assumable'
  }
})

export interface GoodValueTagProps {
  listing: ListingCompact | Listing
}

export const GoodValueTag = ({ listing }: GoodValueTagProps) => {
  const intl = useIntl()
  return listing?.hasSellerConcession ? (
    <Flex
      alignItems='center'
      justifyItems='center'
      borderWidth={1}
      borderRadius={9}
      borderColor='neutral.500'
      backgroundColor='neutral.100'
      gap={2}
      px={2}
      py={1}
      ml={2}
    >
      {listing?.assumableRatePercent ? (
        <Text margin={0} fontWeight={600} size='2xs'>
          {listing?.assumableRatePercent}% {intl.formatMessage(MSG.assumable)}
        </Text>
      ) : (
        <>
          <Icon name='refi' h={4} w={4} />
          <Text margin={0} fontWeight={600} size='2xs'>
            {intl.formatMessage(MSG.goodValue)}
          </Text>
        </>
      )}
    </Flex>
  ) : null
}
