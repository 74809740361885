import { Box, Button, Flex, Heading, Link, LoadingSpinner, Text } from '@homebotapp/hb-react-component-catalog'
import { FeedBase, FeedType, ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'
import { useGetFeed } from '../../../hooks/gallery/useFeed'
import { defineMessages, useIntl } from 'react-intl'
import { GalleryList } from '../GalleryList/GalleryList'
import { useLocation, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { RootState } from '../../../types/rootState'
import { selectHomeValue } from '../../../store/selectors/home/metadata'
import { useToggleFeedSubscribed } from '../../../hooks/gallery/useToggleFeedSubscribed'
import { useState } from 'react'
import { GallerySubscribeModal } from '../GallerySubscribeModal/GallerySubscribeModal'
import { isAuthTokenSupported } from '../../../auth'
import { SEARCH_PATH_HELPERS } from '../../../constants/navigation'
import { AssumablesLearnMoreModal } from '../AssumablesLearnMoreModal/AssumablesLearnMoreModal'
import { BuyerPerksLearnMoreModal } from '../BuyerPerksLearnMoreModal/BuyerPerksLearnMoreModal'
import { selectClientId } from '../../../store/selectors/client'
import { selectClientHomes } from '../../../store/selectors/client'

export interface FeedProps {
  feed: FeedBase
}

export const MSG = defineMessages({
  viewAll: {
    id: 'General.viewAll',
    defaultMessage: 'View all'
  },
  findAPlace: {
    id: 'General.findAPlace',
    defaultMessage: 'Find a place...'
  },
  for: {
    id: 'General.for',
    defaultMessage: 'For'
  },
  recentPriceDrop: {
    id: 'Gallery.priceDrops',
    defaultMessage: 'Price Drops'
  },
  recentPriceDropDescription: {
    id: 'Gallery.priceDropsDescription',
    defaultMessage:
      'See places with a reduced asking price, and subscribe to this gallery to receive automatic email updates!'
  },
  assumables: {
    id: 'Gallery.assumables',
    defaultMessage: 'Assumable Loans'
  },
  assumablesDescription: {
    id: 'Gallery.assumablesDescription',
    defaultMessage:
      "Ask your lender if you qualify for an assumable mortgage. You may be able to save by taking over the seller's low-rate loan!"
  },
  savedSearch: {
    id: 'General.savedSearch',
    defaultMessage: 'Saved Search'
  },
  favorites: {
    id: 'Gallery.favorites',
    defaultMessage: 'Favorites'
  },
  concessions: {
    id: 'Gallery.concessions',
    defaultMessage: 'Buyer perks'
  },
  concessionsDescription: {
    id: 'Gallery.concessionsDescription',
    defaultMessage: 'Sellers cover part of your expenses'
  },
  recentlySold: {
    id: 'Gallery.recentlySold',
    defaultMessage: 'Recently Sold'
  },
  recentlySoldDescription: {
    id: 'Gallery.recentlySoldDescription',
    defaultMessage:
      'Explore to see what the market is like, and subscribe to this gallery to receive automatic email updates!'
  },
  yourStuff: {
    id: 'General.yourStuff',
    defaultMessage: 'Your Stuff'
  },
  localGallery: {
    id: 'General.localGallery',
    defaultMessage: 'Local Gallery'
  },
  forYou: {
    id: 'General.forYou',
    defaultMessage: 'For You'
  },
  help: {
    id: 'Gallery.help',
    defaultMessage: 'Let’s find your next home'
  },
  changeLocation: {
    id: 'General.changeLocation',
    defaultMessage: 'Change Location'
  },
  subscribe: {
    id: 'Gallery.subscribe',
    defaultMessage: 'Subscribe'
  },
  subscribed: {
    id: 'Gallery.subscribed',
    defaultMessage: 'Subscribed'
  },
  forHome: {
    id: 'Gallery.forHome',
    defaultMessage: 'For your home in'
  }
})

export const Feed = ({ feed }: FeedProps) => {
  const intl = useIntl()
  const homes = useSelector(selectClientHomes)
  const homeValue = useSelector<RootState, number>(selectHomeValue)
  const { mutateAsync: toggleSub, isLoading: togglingSub } = useToggleFeedSubscribed()
  const [showSubscribedModal, setShowSubscribedModal] = useState(false)
  const { data, isFetching, refetch } = useGetFeed({
    feedId: feed?.id,
    options: {
      priceAnchor: homeValue ?? undefined
    }
  })
  const params = useParams<{ customerProfileId: string; clientId: string }>()
  const clientId = useSelector(selectClientId)

  const toggleSubscription = async () => {
    if (!isAuthTokenSupported()) {
      return
    }
    await toggleSub({ feedId: feed.id })
    await refetch()
    if (data?.feed.subscribed) {
      return
    }
    setShowSubscribedModal(true)
  }

  const learnMoreAssumableButtonProps = {
    variant: 'link',
    colorScheme: 'primary',
    size: 'xs',
    width: 'fit-content',
    tracking: {
      guid: '2qHGXjzHKCCCPDKnBtaA7J',
      ui_context: 'For You Gallery Feed',
      descriptor: 'Info button to learn more about assumables'
    }
  }

  const renderGalleryTile = (titleKey: string, descriptionKey: string) => {
    return (
      <Box width='100%'>
        <Flex gap={2} align='flex-end' justify='flex-start'>
          <Heading as='h3' m={0} variant='eyebrow' color='brand'>
            {intl.formatMessage(MSG[titleKey])}
          </Heading>
          {data?.feed?.listings && data.feed.listings.length > 0 && (
            <Link
              href={SEARCH_PATH_HELPERS.gallery.buildPath(params.customerProfileId, feed.id, clientId)}
              size={'xs'}
              mb='.25rem'
              tracking={{
                ui_context: 'Gallery_Feed',
                descriptor: `Clicked on Feed ${feed?.feedType}`,
                guid: `3GtcZMbe9HLD7YUKKiAyjg_${feed?.feedType}`
              }}
            >
              {intl.formatMessage(MSG.viewAll)}
            </Link>
          )}
        </Flex>
        <Heading mb={0} as='h4' size='xs' fontStyle='italic'>
          {intl.formatMessage(data?.feed?.homeId ? MSG.forHome : MSG.for)}{' '}
          {data?.feed?.homeId ? homes.find(home => home.uuid === data?.feed?.homeId).address.zip : feed.location.name}
        </Heading>
        <Text m={0} size='xs'>
          {intl.formatMessage(MSG[descriptionKey])}
          {data?.feed?.feedType === FeedType.Assumables && (
            <Text as='span' ml={1}>
              <AssumablesLearnMoreModal buttonProps={learnMoreAssumableButtonProps} />
            </Text>
          )}
          {data?.feed?.feedType === FeedType.Concessions && (
            <Text as='span' ml={1}>
              <BuyerPerksLearnMoreModal asLink={true} />
            </Text>
          )}
        </Text>
        {data?.feed && (
          <Button
            mt={2}
            size='xs'
            onClick={() => {
              toggleSubscription()
            }}
            tracking={{
              guid: data.feed.subscribed ? '6NU8QA8FBwaqxj40PgLWEN' : '6NU8QA8FBwaqxj30PgLSUB',
              ui_context: 'GALLERY_SUBSCRIBE_BUTTON',
              descriptor: data.feed.subscribed
                ? `Subscribe to ${data.feed.feedType} feed`
                : `Unsubscribe from ${data.feed.feedType} feed`
            }}
            colorScheme={data.feed.subscribed ? 'secondary' : 'primary'}
          >
            <Text size='xs' textColor={data.feed.subscribed ? 'primary.500' : 'black'} fontWeight={600} m={0}>
              {intl.formatMessage(data.feed.subscribed ? MSG.subscribed : MSG.subscribe)}
            </Text>
          </Button>
        )}
        {showSubscribedModal && (
          <GallerySubscribeModal
            feedCategory={data?.feed.feedType as FeedType}
            onClose={() => {
              setShowSubscribedModal(false)
            }}
          />
        )}
      </Box>
    )
  }

  if (isFetching) {
    return (
      <Flex justifyContent='center' mt={5}>
        <LoadingSpinner />
      </Flex>
    )
  }

  return (
    <Box mb={8}>
      {renderGalleryTile(feed.feedType, `${feed.feedType}Description`)}
      <Box mt={5}>
        <GalleryList
          feedType={feed.feedType}
          listings={data?.feed.listings.slice(0, 4) as ListingCompact[]}
          clientId={clientId as string}
        />
      </Box>
    </Box>
  )
}
