import { UseQueryOptions } from 'react-query'
import { useCallback, useMemo } from 'react'
import { useToast } from '@homebotapp/hb-react-component-catalog'
import { QueryKey } from '../../constants/queryKey'
import { useFavoriteListingsQuery } from '../../api/gqlaxy/gql/generated/graphql-hooks'
import { graphqlGLClient } from '../../api/gqlaxy/gql/queries/graphQLClient'
import { FavoriteListingsQuery, FavoriteListingsQueryVariables } from '../../api/gqlaxy/gql/generated/graphql'
import { getBearerToken, hasCustomerToken } from '../../auth'
import { useSelector } from 'react-redux'
import { selectClientIsGuest } from '../../store/selectors/client'

interface ErrorPrompt {
  error: string
  message: string
}

interface Option<D, E> extends UseQueryOptions<D, E> {
  disableErrorPrompts?: boolean
  errorPrompts?: ErrorPrompt[]
}

export const useGetFavoriteListings = (
  param: FavoriteListingsQueryVariables,
  option: Option<FavoriteListingsQuery, Error> = {
    queryKey: QueryKey.GetFavoriteListings as any
  }
) => {
  const toast = useToast()

  const isGuest = useSelector(selectClientIsGuest)

  const isImpersonating = hasCustomerToken()

  const bearerToken = useMemo(() => getBearerToken(), [])

  const client = graphqlGLClient({
    // endpoint: 'http://127.0.0.1:5001/mobile-staging-376418/us-central1/gqlactus' // local endpoint
  })

  const { disableErrorPrompts, errorPrompts, ...options } = option

  const handleError = useCallback(
    (...args) => {
      const [e] = args

      if (disableErrorPrompts) return

      const prompt = errorPrompts?.find(({ error }) => error === e.message)
      const message = prompt?.message ?? e.message

      toast({
        description: message,
        status: 'error',
        duration: 4000,
        position: 'top-right'
      })
    },
    [disableErrorPrompts, errorPrompts, toast]
  )

  return useFavoriteListingsQuery(client, param, {
    queryKey: [QueryKey.GetFavoriteListings, param.clientId],
    onError: handleError,
    enabled: !!bearerToken && !isGuest && !isImpersonating,
    keepPreviousData: true,
    staleTime: Infinity,
    ...options
  })
}
