import { format } from 'date-fns'
import { useIntl, defineMessages } from 'react-intl'
import { Flex, Text } from '@homebotapp/hb-react-component-catalog'

export const MSG = defineMessages({
  sold: {
    id: 'Listing.ListingSoldDateTag.sold',
    defaultMessage: 'Sold: {soldDate}'
  }
})

export interface ListingSoldDateTagProps {
  soldDate?: string | null
}

export const ListingSoldDateTag = ({ soldDate }: ListingSoldDateTagProps) => {
  const intl = useIntl()

  if (!soldDate) return null

  return (
    <Flex
      px={2}
      py={1}
      gap={2}
      borderWidth={1}
      borderRadius={9}
      alignItems='center'
      justifyItems='center'
      borderColor='neutral.500'
      backgroundColor='neutral.100'
    >
      <Text margin={0} fontWeight={600} size='2xs' color='neutral.700'>
        {intl.formatMessage(MSG.sold, { soldDate: format(new Date(soldDate), 'MM/dd/yyyy') })}
      </Text>
    </Flex>
  )
}
