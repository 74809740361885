/* eslint-disable prettier/prettier */
// PLACE ALL BUYER DIGEST RELATED TRACKING EVENTS HERE

export const BUYER_DIGEST_AIRDNA_MODULE = 'buyer_digest_airdna_module'
export const BUYER_DIGEST_FOOTER = 'buyer_digest_footer'
export const BUYER_DIGEST_MARKET_EXPLORER_INDEX_PAGE_SIDEBAR = 'buyer_digest_market_explorer_index_page_sidebar'
export const BUYER_DIGEST_MARKET_EXPLORER_SHOW_PAGE_SIDEBAR = 'buyer_digest_market_explorer_show_page_sidebar'
export const BUYER_DIGEST_MARKET_EXPLORER_ZIP_CODE_DETAILS_SIDEBAR = 'buyer_digest_market_explorer_zip_code_details_sidebar'
export const BUYER_DIGEST_MOVING_TIMELINE_MODULE = 'buyer_digest_moving_timeline_module'
export const BUYER_DIGEST_NATIVE_APP_AD_MODULE = 'buyer_digest_native_app_ad_module'
export const BUYER_DIGEST_ONBOARDING_WIZARD_CLOSE_DATE = 'buyer_digest_onboarding_wizard_close_date'
export const BUYER_DIGEST_MOBILE_SHORTCUTS_DRAWER = 'buyer_digest_mobile_shortcuts_drawer'
export const BUYER_DIGEST_SIDE_NAVIGATION_PANEL = 'buyer_digest_side_navigation_panel'
