import classnames from 'classnames'
import Carousel from '../Carousel'
import { CarouselBreakpoint } from '../../../types/listings'
import { ListingsCarouselLastSlide } from './ListingsCarouselLastSlide'
import { ListingCompact } from '../../../api/gqlaxy/gql/generated/graphql'
import { ListingCardNew } from '../../homeSearch/ListingCardNew/ListingCardNew'
import styles from './ListingsCarousel.module.scss'
import { Box, ComponentTrackingType } from '@homebotapp/hb-react-component-catalog'

export interface ListingsCarouselProps {
  className?: string
  lastSlideUrl: string
  slidesToShow?: number
  slidesToScroll?: number
  listings: ListingCompact[]
  tracking: ComponentTrackingType
  responsive?: CarouselBreakpoint[]
}

export const ListingsCarousel = ({
  tracking,
  listings,
  className,
  responsive,
  lastSlideUrl,
  slidesToShow = 3,
  slidesToScroll = 3
}: ListingsCarouselProps) => {
  const slides = listings
    ?.map(l => (
      <Box mx={1}>
        <ListingCardNew listing={l} tracking={tracking} />
      </Box>
    ))
    ?.concat(<ListingsCarouselLastSlide url={lastSlideUrl} />)

  return (
    slides && (
      <Carousel
        arrows
        swipeToSlide
        slides={slides}
        infinite={false}
        responsive={responsive}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        className={classnames(className, styles.listingCarousel)}
      />
    )
  )
}
